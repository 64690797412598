<template>
<div>
  <div>
    <i :class="trigger.icon"></i> {{ trigger.label }}
  </div>

  <b-modal id="date-modal" v-model="isShowModal" title="Webhook" size="lg">
    <div class="row">
      <div class="col-12">
        <p class="text-center font-weight-bold">Your webhook URL<br/>You'll need to configure your application with this webhook URL.</p>
        <div class="d-block">
          <textarea
            ref="webhookUrlText"
            v-model="automation.options.webhook"
            name="code"
            cols="30"
            rows="2"
            class="form-control"
            readonly
            @click="copyUrl"
          ></textarea>
          <div class="text-right mt-1">
            <a href="javascript:void(0)" @click="copyUrl">
              Copy Url
            </a>
          </div>
          <div class="mt-4" style="font-weight: 600;">
              <p>Contact Payload: </p>
              <div class="payload-wrapper">
              {
                <span class="d-block pl-4">"first_name": "string",</span>
                <span class="d-block pl-4">"last_name": "string",</span>
                <span class="d-block pl-4">"phone": "string",</span>
                <span class="d-block pl-4">"email": "string",</span>
                <span class="d-block pl-4">"birthday": "string ( e.g 1995-12-24 )",</span>
                <span class="d-block pl-4">"anniversary": "string ( e.g 1995-12-24 )",</span>
                <span v-for="(item, index) in customFields" :key="index" class="d-block pl-4">"custom_{{item['id']}}": "{{ getCustomFieldDesc(item) }}"</span>
              }
              </div>
          </div>
          <div class="mt-4" style="font-weight: 600;">
              <p>We're listening<br/>Have your application send a request to the webhook URL to run this automation.</p>
          </div>
        </div>
        <div class="form-group small-checkbox mt-4">
          <b-form-checkbox v-model="automation.options.stop_on_reply" name="stop_on_reply">
            Stop on contact reply 
            <span v-b-tooltip.hover class="cursor-pointer" title="When someone replies to a message in the automation the remainder of the automation will be cancelled" style="vertical-align: middle;">
              <i  class="uil uil-question-circle"></i>
            </span>
          </b-form-checkbox>
        </div>
        <div class="form-group small-checkbox mt-2 pt-2">
          <b-form-checkbox v-model="automation.options.double_optin" name="doubleOptin" :value="true"
            class="d-block d-flex align-items-center">SMS Double opt-in (recommended)<br />
            <small>Requires new subscribers to confirm their mobile number before adding to this list and sending messages.</small>
          </b-form-checkbox>
        </div>
        <div class="form-group small-checkbox mt-2 pt-2">
          <b-form-checkbox v-model="automation.options.email_double_optin" name="email_double_optin" :value="true"
            class="d-block d-flex align-items-center">Email Double opt-in <br />
            <small>Requires new subscribers to confirm their email before adding to this list and sending emails.</small>
          </b-form-checkbox>
        </div>
        <div v-if="automation.options.email_double_optin" class="col-md-12 small-checkbox mt-2">
          <label for="">Redirect URL <span v-b-tooltip.hover class="cursor-pointer" title="User will be redirected to this URL after clicking on confirmation link." style="vertical-align: middle;">
             <i  class="uil uil-question-circle"></i>
          </span></label>
          <TextInput v-model="automation.options.optin_email_redirect_url" rules="url" name="optin_email_redirect_url" />
        </div>

      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import Vue from 'vue'

export default {
  components: {
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
    }
  },

  computed: {
    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  mounted() {
    this.$parent.$on('showModal', this.showModal)
    this.automation.options = {
      ...{  email_double_optin: false, optin_email_redirect_url: null },
      ...this.automation.options
    }
  },

  methods: {
    getCustomFieldDesc(item) {
      return `${item['type_str'].toLowerCase()} ( ${item['name']}${item['type'] === 2 ? ' - e.g 1995-12-24': ''} )`
    },

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    copyUrl() {
      this.$refs.webhookUrlText.select()
      this.$refs.webhookUrlText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.payload-wrapper {
  background: #d0d0d080;
  padding: 10px;
}
</style>

<style lang="scss">
#date-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>